import { useTranslation } from 'react-i18next';
import { App, Popover, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { getWritingDoc, getWritingDocList, setTipsExistFlag, upsertWritingDoc } from '@/services';
import CustomIcon from '@/components/CustomIcon';
import { extractHeadings, writeDocTimeTransform } from '@/common/utils';
import ToastContent from '@/components/ToastContent';
import { DEFAULT_WRITING_JSON, RESPONSE_DATA_CODE } from '@/common/config';
import { useWriteStore } from '@/store';
import { isEmpty, isFunction, isNumber } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { Content, JSONContent } from '@tiptap/react';
import { useDA, useGASendEvent, useReadOnlyBlockEditor } from '@/hooks';
import { useDebounceFn, useRequest } from 'ahooks';
import CirclePng from '@/assets/circle.png';
import classNames from 'classnames';

import type { DocListItem, DocResult, ResponseType, UpsertDocResult } from '@/type';
import type { TooltipPlacement } from 'antd/es/tooltip';

import './index.less';

interface ChatImage {
  src?: string;
  width?: number | string;
  height?: number | string;
}

interface Position {
  top: string | number;
  left: string | number;
}

interface AddWritingProps {
  defaultTitle?: string;
  addText?: string;
  placement?: TooltipPlacement;
  tipsPos?: Position;
  getChatImage?: () => Promise<ChatImage | undefined>;
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
  addFinishCallback?: (docId: string) => void;
  closeOperation?: () => void;
  children?: React.ReactNode;
}

interface AddWritingObjProps {
  docId: string;
  docTitle: string;
  docContent: string;
  channelId: string;
}

const FAKE_DOCID = 0;

const AddWriting: React.FC<AddWritingProps> = (props) => {
  const {
    defaultTitle,
    addText,
    placement = 'bottom',
    tipsPos,
    getChatImage,
    getPopupContainer,
    addFinishCallback,
    closeOperation,
    children,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendEvent } = useGASendEvent();

  const {
    setDocId,
    setDocTitle,
    setDocInitContent,
    setInsertStartPos,
    tipsFlag,
    setTipsFlag,
    addTipFlag,
    setHeadings,
    setChannelId,
  } = useWriteStore();
  const { editor } = useReadOnlyBlockEditor();

  const { message } = App.useApp();
  const { sendDAEvent } = useDA();

  const [docList, setDocList] = useState<DocListItem[]>([]);
  const [open, setOpen] = useState(false);
  const [selectDocId, setSelectDocId] = useState<string | number | null>(null);

  const handleJump = async (docId: string, docTitle: string) => {
    sendDAEvent('OpenHistoryAiWritingfile', {
      page_name: 'chat',
      doc_id: docId,
      filename: docTitle,
    });

    message.destroy();
    navigate(`/writing/${docId}`, {
      state: {
        ignoreGetDoc: true,
      },
    });
  };

  const messageContentRender = ({ docId, docTitle }: AddWritingObjProps) => (
    <Space size={12}>
      <span>{t('components.write.addWritingSuccess')}</span>
      <button className="continue-btn" onClick={() => handleJump(docId, docTitle)}>
        {t('components.write.continueEdit')}
      </button>
      {/* <CustomIcon type="close" style={{ cursor: 'pointer' }} onClick={() => message.destroy()} /> */}
    </Space>
  );

  const calcInsertStartPos = (content: Content) => {
    if (editor) {
      editor.commands.setContent(content);
      let startPos = 0;
      if (!editor.isEmpty) {
        startPos = editor.state.doc.content.size + 1;
      }
      setInsertStartPos(startPos);
    }
  };

  const updateHeadings = (content: JSONContent[]) => {
    const newHeadings = extractHeadings(content);
    setHeadings(newHeadings);
  };

  const setUpsertData = (data: AddWritingObjProps) => {
    setOpen(false);
    closeOperation?.();
    setSelectDocId(null);
    setDocId(data?.docId);
    setDocTitle(data?.docTitle || '');
    setChannelId(data?.channelId || '');
    if (data?.docContent) {
      setDocInitContent(data.docContent);
      const content = JSON.parse(data.docContent);
      updateHeadings(content?.content);
    }
    if (tipsFlag?.length > 0 && tipsFlag?.[1] === '0' && !addTipFlag) {
      const flag = '010';
      setTipsFlag(flag);
      setTipsExistFlag({
        flag,
      });
    }

    message.open({
      content: <ToastContent icon="success" content={messageContentRender(data)} />,
      duration: 2,
      style: tipsPos
        ? {
            position: 'absolute',
            ...tipsPos,
          }
        : undefined,
    });
    sendEvent('AddtoWrite_Success', {
      docId: data?.docId,
    });
  };

  const getImageContent = async () => {
    const image = await getChatImage?.();
    const imageJSON = {
      type: 'doc',
      content: [
        {
          type: 'imageBlock',
          attrs: {
            ...image,
            align: 'center',
          },
        },
      ],
    };
    return JSON.stringify(imageJSON);
  };

  const handleNewWriting = async (event: React.MouseEvent) => {
    sendDAEvent('CreateNewAiWriting', {
      forward_source: 'save_dropdown_new',
    });

    event.stopPropagation();
    if (selectDocId === FAKE_DOCID) {
      return;
    }
    setSelectDocId(FAKE_DOCID);
    setInsertStartPos(0);
    let content = addText;
    if (isFunction(getChatImage)) {
      content = await getImageContent();
    }
    try {
      const title = defaultTitle || '';
      const { data } = await upsertWritingDoc<ResponseType<UpsertDocResult>>({
        name: title,
        content,
      });
      setUpsertData({
        docId: data.docId,
        docTitle: title,
        docContent: content as string,
        channelId: data.channelId,
      });
      addFinishCallback?.(data.docId);
    } catch (err: any) {
      if (err?.response?.data?.code !== RESPONSE_DATA_CODE.UsageExceedsLimit) {
        message.open({
          content: <ToastContent icon="error" content={t('components.write.upsertError')} />,
        });
      }

      setSelectDocId(null);
    }
  };

  const handleAddWriting = async (item: DocListItem, event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      if (item.docId === selectDocId) {
        return;
      }
      setSelectDocId(item.docId);
      const { data } = await getWritingDoc<ResponseType<DocResult>>({ docId: item.docId });
      const existingContent = data?.content ? JSON.parse(data.content) : DEFAULT_WRITING_JSON;
      calcInsertStartPos(existingContent);
      let content = addText;
      if (isFunction(getChatImage)) {
        content = await getImageContent();
      }
      const newContent = JSON.parse(content || '');

      const mergedContent = {
        ...existingContent,
        content: [...(existingContent.content || []), ...(newContent.content || [])],
      };
      const mergedContentStr = JSON.stringify(mergedContent);
      const res = await upsertWritingDoc<ResponseType<UpsertDocResult>>({
        docId: item.docId,
        name: item.name,
        content: mergedContentStr,
      });
      setUpsertData({
        docId: res.data.docId,
        docTitle: data.name,
        docContent: mergedContentStr,
        channelId: res.data.channelId,
      });
      addFinishCallback?.(res.data.docId);
    } catch (e) {
      console.error(e);
    }
  };

  const { run: handleNewWritingDebounce } = useDebounceFn(handleNewWriting, {
    wait: 500,
  });

  const { loading } = useRequest(getWritingDocList, {
    ready: open,
    cacheKey: 'getWritingDocList',
    onSuccess: (res: ResponseType<DocListItem[]>) => {
      setDocList(res?.data || []);
    },
  });

  useEffect(() => {
    return () => {
      message.destroy();
    };
  }, []);

  const contentRender = () => {
    return (
      <div>
        <div className="write-list-header">
          <div className="write-list-header-desc">{t('components.write.addWritingDesc')}</div>
          <button
            className={classNames({
              'add-btn-adding': isNumber(selectDocId),
            })}
            onClick={handleNewWritingDebounce}
          >
            <Space size={6}>
              <CustomIcon type="plus" className="plus-icon" />
              {t('components.write.newWriting')}
            </Space>
            {selectDocId === FAKE_DOCID && (
              <img src={CirclePng} alt="loading" className="loading-icon" />
            )}
          </button>
        </div>
        <Skeleton
          loading={loading && isEmpty(docList)}
          active
          title={false}
          paragraph={{ rows: 4 }}
        >
          <div
            className={classNames({
              'write-list': true,
              'write-list-adding': isNumber(selectDocId),
            })}
          >
            {docList.map((item) => {
              const title = item.name || t('common.untitled');
              return (
                <div
                  className="list-item"
                  key={item.docId}
                  onClick={(event) => handleAddWriting(item, event)}
                >
                  <div className="list-item-left ellipsis">
                    <CustomIcon type="writeDocListItem" className="doc-icon" />
                    <span className="wrtite-title ellipsis" title={title}>
                      {title}
                    </span>
                  </div>
                  <div className="list-item-right">
                    {item.docId === selectDocId ? (
                      <img src={CirclePng} alt="loading" className="loading-icon" />
                    ) : (
                      writeDocTimeTransform(item.updatedAt)
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </Skeleton>
      </div>
    );
  };

  return (
    <Popover
      open={open}
      destroyTooltipOnHide
      arrow={false}
      placement={placement}
      content={contentRender()}
      autoAdjustOverflow
      overlayClassName="add-writing-container"
      trigger={['click']}
      onOpenChange={setOpen}
      getPopupContainer={getPopupContainer}
    >
      {children}
    </Popover>
  );
};

export default AddWriting;
